import React, { useState } from 'react';
import { Container, Row, Col, Collapse } from 'reactstrap';
import CarbonCreditNFTs from './Collapses/CCNfts';
import GreenMetaverse from './Collapses/GreenMetaverse';
import Staking from './Collapses/Staking';

function MoreInfo() {
	const [activeCard, setActiveCard] = useState(-1);
	return (
		<Container fluid className='px-3 px-md-5'>
			<Row className='mt-10 mb-5 justify-content-center'>
				{activeCard == -1 ? (
					<>
						<Col lg='4' md='6'>
							<div className='heading-card'>
								<h5 className='heading-card__title'>
									Green Metaverse Initiatives
								</h5>
								<span
									className='heading-card__read'
									onClick={() => setActiveCard(1)}
								>
									Get Started{' '}
									<i className='fa fa-arrow-right ml-5'></i>
								</span>
							</div>
						</Col>
						<Col lg='4' md='6' className='mt-4 mt-md-0'>
							<div className='heading-card'>
								<h5 className='heading-card__title'>Staking</h5>
								<span
									className='heading-card__read'
									onClick={() => setActiveCard(2)}
								>
									Get Started{' '}
									<i className='fa fa-arrow-right ml-5'></i>
								</span>
							</div>
						</Col>
						<Col lg='4' md='6' className='mt-4 mt-lg-0'>
							<div className='heading-card'>
								<h5 className='heading-card__title'>
									Carbon Credit NFTs
								</h5>
								<span
									className='heading-card__read'
									onClick={() => setActiveCard(3)}
								>
									Get Started{' '}
									<i className='fa fa-arrow-right ml-5'></i>
								</span>
							</div>
						</Col>
					</>
				) : (
					<>
						<Collapse isOpen={activeCard == 1}>
							<GreenMetaverse setActiveCard={setActiveCard} />
						</Collapse>
						<Collapse isOpen={activeCard == 2}>
							<Staking setActiveCard={setActiveCard} />
						</Collapse>
						<Collapse isOpen={activeCard == 3}>
							<CarbonCreditNFTs setActiveCard={setActiveCard} />
						</Collapse>
					</>
				)}
			</Row>
		</Container>
	);
}

export default MoreInfo;
