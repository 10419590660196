import React from 'react';
import { Button, Container, Input } from 'reactstrap';
function About() {
	return (
		<div className='hero__about mt-5 mt-md-10'>
			<Container>
				<h1 className='text-center hero__about-title'> PERFUEL</h1>
				<div className='position-relative hero__about__form-container mx-auto mt-5'>
					{/* <Input
						type='email'
						placeholder='Enter your email'
						className='hero__about__form-container-form-control'
					/>
					<Button className='btn__secondary hero__about__form-container-btn'>
						Subscribe
					</Button> */}
				</div>
				<div className='mt-5 mt-md-10 text-center'>
					<h5 className='hero__about__heading'>
						A Blockchain-based Carbon Credit & Green Initiative
						Ecosystem
					</h5>
					<p className='mt-5 hero__about__desc'>
						This project will invest in green carbon offset credits
						to be held in reserve with an expected annual increase
						in overall value. The investment will also be dedicated
						to sustainable green companies that can demonstrate a
						return for our project investors.
					</p>
					<Button className='btn__primary mt-5'>
						Get Started
						<i className='fa fa-arrow-right ml-3'></i>
					</Button>
				</div>
			</Container>
		</div>
	);
}

export default About;
