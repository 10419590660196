import React, { useState } from 'react';
import { Container, Label, Input, Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { buyToken, getGenericData, getUserData } from '../utils/web3.service';

function Presale() {
	const {bnbBalance, perfuelBalance, totalTokenSold,tokenPrice, walletAddress} = useSelector(state=>state?.web3Store);

	const [bnbAmount,setBnbAmount] = useState(0);
	const [prfAmount,setPrfAmount] = useState(0);
	const [loading,setLoading] = useState(false);

	const buyButtonHandler = async()=>{
		try{
			setLoading(true);
			
			const trx = await buyToken(bnbAmount);
			
			await getUserData(walletAddress);
			await getGenericData()
			setLoading(false);
			setBnbAmount(0);
			setPrfAmount(0);
		}
		catch(err){
			console.log(err);
			setLoading(false);
			throw new Error(err?.message);
		}
	}

	return (
		<Container className='dashboard__presale mt-3 pb-2'>
			<h2 className='text-center text-white mb-5'>Presale Dashboard</h2>

			<div className='dashboard__presale__card'>
				<div className='d-flex'>
					<Label className='label'>Enter Amount</Label>
					<Label className='balance ml-auto'>
						Balance : {bnbBalance?.toFixed(2) || 0.00}{' '}
						<span className='text-site-primary'>BNB</span>
					</Label>
				</div>
				<div className='mt-2 position-relative'>
					<Input
						type='number'
						placeholder='0.00'
						className='dashboard__presale__card__form-control'
						value={bnbAmount}
						onChange={(e)=>{
							setBnbAmount(e?.target?.value);
							setPrfAmount(e?.target?.value/tokenPrice);
						}}
					/>
					<span className='dashboard__presale__card__addon'>BNB</span>
				</div>
				<div className='d-flex mt-4'>
					<Label className='label'>Enter Amount</Label>
					<Label className='balance ml-auto'>
						Balance : {perfuelBalance?.toFixed(2) || 0.00}{' '}
						<span className='text-site-primary'>PRF</span>
					</Label>
				</div>
				<div className='mt-2 position-relative'>
					<Input
						type='number'
						placeholder='0.00'
						className='dashboard__presale__card__form-control'
						value={prfAmount}
						onChange={(e)=>{
							setPrfAmount(e?.target?.value);
							setBnbAmount(e?.target?.value*tokenPrice);
						}}
					/>
					<span className='dashboard__presale__card__addon'>PRF</span>
				</div>
				<div className='mt-4 text-center'>
					<h5 className='balance'>
						<strong className='text-white'>
							Total Token Sold :{' '}
							<span className='text-site-primary'>
								{totalTokenSold?.toFixed(2) || 0.00} PRF
							</span>
						</strong>
					</h5>
				</div>
				<Button className='btn__primary mt-4' block disabled={loading || !bnbAmount} onClick={buyButtonHandler}>
					{!loading?"Buy Now":'Buying...'}
				</Button>
			</div>
		</Container>
	);
}

export default Presale;
