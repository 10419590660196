import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    walletAddress:"",
    bnbBalance:0,
    perfuelBalance:0,
    tokenPrice:0.005,
    totalTokenSold:0,
    totalStaked:0,
    userDeposits:[],
    totalReward:0,
    totalStakedInTheContract:0,
}

const web3Store = createSlice({
    name:"wallet",
    initialState,
    reducers: {
        setWalletAddress(state,action){
            state.walletAddress = action.payload;
        },
        setBNBBalance(state,action){
            state.bnbBalance = action.payload;
        },
        setPerfuelBalance(state,action){
            state.perfuelBalance = action.payload;
        },
        setTotalTokenSold(state,action){
            state.totalTokenSold = action.payload;
        },
        setTotalStaked(state,action){
            state.totalStaked = action.payload;
        },
        setUserDeposits(state,action){
            state.userDeposits = action.payload;
        },
        setTotalReward(state,action){
            state.totalReward = action.payload;
        },
        setTotalStakedInTheContract(state,action){
            state.totalStakedInTheContract = action.payload;
        },
    }
})

export const { 
    setWalletAddress,
    setBNBBalance, 
    setPerfuelBalance,
    setTotalTokenSold,
    setTotalStaked,
    setUserDeposits,
    setTotalReward,
    setTotalStakedInTheContract,
} = web3Store.actions;
export default web3Store.reducer;