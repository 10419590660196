import React, { useState } from 'react';
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	NavLink,
	Button,
} from 'reactstrap';
import Logo from '../../assets/imgs/logo.png';
import ArrowUp from '../../assets/imgs/arrow-up.png';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { connectWallet } from '../../utils/web3.service';

function Header({ aboutScroll, tokenScroll, stakingScroll }) {
	const { walletAddress } = useSelector(state => state?.web3Store);
	const [isOpen, setIsOpen] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();

	const toggle = () => setIsOpen(!isOpen);

	return (
		<div>
			<Navbar expand='md' className='hero__navbar'>
				<NavbarBrand tag={Link} to='/'>
					<img src={Logo} alt='logo' height='48px' width='100%' />
				</NavbarBrand>
				<NavbarToggler onClick={toggle}>
					<i className='fa fa-bars'></i>
				</NavbarToggler>
				<Collapse isOpen={isOpen} navbar>
					{history.location.pathname == '/' ? (
						<>
							<Nav className='ml-auto mr-2 text-center' navbar>
								<NavItem onClick={aboutScroll}>
									<NavLink>About Us</NavLink>
								</NavItem>
								<NavItem onClick={stakingScroll}>
									<NavLink>Staking</NavLink>
								</NavItem>
								<NavItem onClick={tokenScroll}>
									<NavLink>Tokenomics</NavLink>
								</NavItem>
							</Nav>
							<Button
								className='btn__primary-header'
								onClick={() => history.push('/presale')}
							>
								Open App{' '}
								<img src={ArrowUp} alt='go' className='ml-3' />
							</Button>
						</>
					) : (
						<>
							<Nav
								className='ml-5 text-center text-md-left'
								navbar
							>
								<NavItem
									style={{
										opacity: `${
											history.location.pathname ==
											'/staking'
												? '0.5'
												: ''
										}`,
									}}
								>
									<NavLink tag={Link} to='/presale'>
										Presale
									</NavLink>
								</NavItem>
								<NavItem
									style={{
										opacity: `${
											history.location.pathname ==
											'/presale'
												? '0.5'
												: ''
										}`,
									}}
								>
									<NavLink tag={Link} to='/staking'>
										Staking
									</NavLink>
								</NavItem>
							</Nav>
							<div className='text-center ml-auto'>
								<Button
									className='btn__primary-header'
									onClick={() => {
										connectWallet();
									}}
								>
									{walletAddress
										? walletAddress?.replace(
												walletAddress?.slice(6, 36),
												'***'
										  )
										: 'Connect'}{' '}
								</Button>
							</div>
						</>
					)}
				</Collapse>
			</Navbar>
		</div>
	);
}

export default Header;
