import React, { useState } from 'react';
import { Container, Label, Input, Button, Row, Col } from 'reactstrap';
import ArrowLoss from '../assets/imgs/arrown-loss.png';
import ArrowGain from '../assets/imgs/arrow-gain.png';
import { useSelector } from 'react-redux';
import {
	claimReward,
	getGenericData,
	getUserData,
	stakeToken,
} from '../utils/web3.service';
function Staking() {
	const {
		totalStaked,
		totalReward,
		perfuelBalance,
		walletAddress,
		totalStakedInTheContract,
	} = useSelector(state => state?.web3Store);

	const [prfAmount, setPrfAmount] = useState(0);
	const [loading, setLoading] = useState(false);
	const [withdrawLoading, setWithdrawLoading] = useState(false);

	const stakeButtonHandler = async () => {
		try {
			setLoading(true);

			const trx = await stakeToken(prfAmount);

			await getUserData(walletAddress);
			await getGenericData();
			setLoading(false);
			setPrfAmount(0);
		} catch (err) {
			console.log(err);
			setLoading(false);
			throw new Error(err?.message);
		}
	};

	const withdrawButtonHandler = async () => {
		try {
			setWithdrawLoading(true);

			const trx = await claimReward();

			await getUserData(walletAddress);
			await getGenericData();
			setWithdrawLoading(false);
		} catch (err) {
			console.log(err);
			setWithdrawLoading(false);
			throw new Error(err?.message);
		}
	};

	return (
		<Container className='dashboard__staking mt-3 pb-5'>
			<h2 className='text-center text-white mb-5'>Staking Dashboard</h2>
			<Row>
				<Col lg='5'>
					<div className='d-flex flex-column justify-content-between h-100'>
						<div className='dashboard__staking__card'>
							<div className='d-flex align-items-start'>
								<span className='dashboard__staking__card-title'>
									Rewards
								</span>
								<div className='ml-auto'>
									<span className='dashboard__staking__card-subtitle'>
										Total Rewards
									</span>
									<br />
								</div>
							</div>
							<h3 className='dashboard__staking__card__number'>
								{totalReward?.toFixed(2) || 0.0}
							</h3>
							<div className='d-flex mt-3'>
								<div>
									<span className='dashboard__staking__card__desc'>
										All your rewards will be shown here on
										this portal
									</span>
								</div>
								<div className='ml-auto'>
									<Button
										className='btn__primary-sm text-nowrap'
										disabled={withdrawLoading}
										onClick={withdrawButtonHandler}
									>
										{!withdrawLoading
											? 'Claim All'
											: 'Claiming...'}
									</Button>
								</div>
							</div>
						</div>
						<div className='dashboard__staking__card mt-3'>
							<div className='d-flex align-items-start'>
								<span className='dashboard__staking__card-title'>
									Your Stakes
								</span>
								<div className='ml-auto'>
									<span className='dashboard__staking__card-subtitle'>
										Stake Price
									</span>
									<br />
								</div>
							</div>
							<h3 className='dashboard__staking__card__number'>
								{totalStaked?.toFixed(2) || 0.0}
							</h3>
							<div className='d-flex mt-3'>
								<div>
									<span className='dashboard__staking__card__desc'>
										All your rewards will be shown here on
										this portal
									</span>
								</div>
							</div>
						</div>
					</div>
				</Col>
				<Col lg='7' className='mt-3 mt-lg-0'>
					<div className='dashboard__staking__card d-flex flex-column justify-content-between'>
						<div>
							<div className='d-flex'>
								<Label className='label'>Enter Amount</Label>
								<Label className='balance ml-auto'>
									Balance :{' '}
									{perfuelBalance?.toFixed(2) || 0.0}{' '}
									<span className='text-site-primary'>
										PRF
									</span>
								</Label>
							</div>
							<div className='mt-2 position-relative'>
								<Input
									type='number'
									placeholder='0.00'
									className='dashboard__staking__card__form-control'
									value={prfAmount}
									onChange={e => {
										setPrfAmount(e?.target?.value);
									}}
									max={0}
								/>
								<span className='dashboard__staking__card__addon'>
									PRF
								</span>
							</div>
						</div>
						<div>
							<div className='mt-4 text-center'>
								<h5 className='balance'>
									<strong className='text-white'>
										Total Tokens Staked :{' '}
										<span className='text-site-primary'>
											{totalStakedInTheContract?.toFixed(
												2
											) || 3145.12}{' '}
											PRF
										</span>
									</strong>
								</h5>
							</div>
							<Button
								className='btn__primary mt-4'
								block
								disabled={loading || !prfAmount}
								onClick={stakeButtonHandler}
							>
								{!loading ? 'Stake Now' : 'Staking...'}
							</Button>
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	);
}

export default Staking;
