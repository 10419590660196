import Landing from '../views/Landing';
import Presale from '../views/Presale';
import Staking from '../views/Staking';

let routes = [
	{
		path: '/',
		component: Landing,
		layout: 'main',
	},
	{
		path: '/presale',
		component: Presale,
		layout: 'dashboard',
	},
	{
		path: '/staking',
		component: Staking,
		layout: 'dashboard',
	},
];
export default routes;
