import React from 'react';
import { Container } from 'reactstrap';

function OurContract() {
	return (
		<Container className='text-center mt-7'>
			<h2 className='heading'>Our Contract</h2>
			<div className='contract__address'>
				<p className='mb-0'>
					https://bscscan.com/token/0x0204aF26023B9d875C7c34a58A27DfC52beBc3C3
				</p>
			</div>
		</Container>
	);
}

export default OurContract;
