import { useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Dashboard from './layout/Dashboard';
import Main from './layout/Main';
import routes from './routes';
import { getGenericData } from './utils/web3.service';

function App() {
	useEffect(() => {
		getGenericData()
	}, [])
	
	return (
		<>
			<BrowserRouter>
				<Switch>
					{routes.map(route => {
						switch (route.layout) {
							case 'main':
								return (
									<Route exact path={route.path}>
										<Main>
											<route.component />
										</Main>
									</Route>
								);
							case 'dashboard':
								return (
									<Route exact path={route.path}>
										<Dashboard>
											<route.component />
										</Dashboard>
									</Route>
								);
						}
					})}
					<Redirect to='/' />
				</Switch>
			</BrowserRouter>
		</>
	);
}

export default App;
